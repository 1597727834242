import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICariIBankaBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICariIBankaBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setKartCariBankaBilgileriSave: number;
  setKartCariBankaBilgileriSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  getKartCariBankaBilgileri: any[];
  getKartCariBankaBilgileri_dummy: any[];
  sbParaBirim: any[];
  sbParaBirim_dummy: any[];
}

export class CariIBankaBilgileri_ScreenBase extends React.PureComponent<ICariIBankaBilgileri_ScreenProps, any> {
  cariibankabilgileri_534000_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "a11337a5-8497-45a9-b3d0-b62de913a3ec",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 608676, PropertyName: "label", Value: "Kaydet" },
        { Id: 997387, PropertyName: "value", Value: "Hesap Adı" },
        { Id: 265099, PropertyName: "placeholder", Value: "..." },
        { Id: 870940, PropertyName: "value", Value: "Para Birimi" },
        { Id: 534000, PropertyName: "placeholder", Value: "..." },
        { Id: 838135, PropertyName: "value", Value: "Banka Ad" },
        { Id: 821142, PropertyName: "placeholder", Value: "..." },
        { Id: 272196, PropertyName: "value", Value: "Şube Adı" },
        { Id: 310421, PropertyName: "placeholder", Value: "..." },
        { Id: 856150, PropertyName: "value", Value: "Şube Kodu" },
        { Id: 953737, PropertyName: "placeholder", Value: "..." },
        { Id: 929635, PropertyName: "value", Value: "Hesap No" },
        { Id: 455759, PropertyName: "placeholder", Value: "..." },
        { Id: 789892, PropertyName: "value", Value: "SWIFT" },
        { Id: 942080, PropertyName: "placeholder", Value: "..." },
        { Id: 9473, PropertyName: "value", Value: "IBAN" },
        { Id: 487916, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.cariibankabilgileri_265099_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_534000_value_kuikaSelectBoxRef = React.createRef();
    this.cariibankabilgileri_821142_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_310421_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_953737_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_455759_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_942080_value_kuikaTextInputRef = React.createRef();
    this.cariibankabilgileri_487916_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setKartCariBankaBilgileriSave: 0,
      GoBack: "",
      getKartCariBankaBilgileri: [],
      sbParaBirim: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("cariibankabilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CariIBankaBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("cariibankabilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("cariibankabilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CariIBankaBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      cariibankabilgileri_265099_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.hesapAd ?? undefined,
      cariibankabilgileri_534000_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.paraBirimID ?? undefined,
      cariibankabilgileri_821142_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.bankaAd ?? undefined,
      cariibankabilgileri_310421_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.subeAd ?? undefined,
      cariibankabilgileri_953737_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.subeKodu ?? undefined,
      cariibankabilgileri_455759_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.hesapNo ?? undefined,
      cariibankabilgileri_942080_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.swift ?? undefined,
      cariibankabilgileri_487916_value: this.state.getKartCariBankaBilgileri?.at?.(0)?.iban ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CariIBankaBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CariIBankaBilgileri/CariIBankaBilgileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getKartCariBankaBilgileri = result?.data.getKartCariBankaBilgileri;
    formVars.cariibankabilgileri_265099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].hesapAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].hesapAd
        : null
    );
    formVars.cariibankabilgileri_534000_value =
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].paraBirimID
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].paraBirimID
        : null;
    formVars.cariibankabilgileri_534000_options = stateVars.sbParaBirim;
    formVars.cariibankabilgileri_821142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].bankaAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].bankaAd
        : null
    );
    formVars.cariibankabilgileri_310421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].subeAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].subeAd
        : null
    );
    formVars.cariibankabilgileri_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].subeKodu
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].subeKodu
        : null
    );
    formVars.cariibankabilgileri_455759_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].hesapNo
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].hesapNo
        : null
    );
    formVars.cariibankabilgileri_942080_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].swift
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].swift
        : null
    );
    formVars.cariibankabilgileri_487916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].iban
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].iban
        : null
    );
    stateVars.sbParaBirim = result?.data.sbParaBirim;

    formVars.cariibankabilgileri_534000_value =
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].paraBirimID
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].paraBirimID
        : null;
    formVars.cariibankabilgileri_534000_options = stateVars.sbParaBirim;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariIBankaBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariIBankaBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.cariibankabilgileri_265099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].hesapAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].hesapAd
        : null
    );

    formVars.cariibankabilgileri_534000_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].paraBirimID
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].paraBirimID
        : null
    );

    stateVars.dataSource_534000 = this.state.sbParaBirim;
    stateVars.dataSource_534000 = this.state.sbParaBirim;
    formVars.cariibankabilgileri_821142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].bankaAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].bankaAd
        : null
    );

    formVars.cariibankabilgileri_310421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].subeAd
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].subeAd
        : null
    );

    formVars.cariibankabilgileri_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].subeKodu
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].subeKodu
        : null
    );

    formVars.cariibankabilgileri_455759_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].hesapNo
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].hesapNo
        : null
    );

    formVars.cariibankabilgileri_942080_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].swift
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].swift
        : null
    );

    formVars.cariibankabilgileri_487916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariBankaBilgileri?.length > 0
        ? stateVars.getKartCariBankaBilgileri[0].iban
        : this.state.getKartCariBankaBilgileri?.length > 0
        ? this.state.getKartCariBankaBilgileri[0].iban
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CariIBankaBilgileriComponent_608676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BankaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_821142_value", "value", "", "", "")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      HesapNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_455759_value", "value", "", "", "")
        ),
        "string"
      ),
      IBAN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_487916_value", "value", "", "", "")
        ),
        "string"
      ),
      SubeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_310421_value", "value", "", "", "")
        ),
        "string"
      ),
      SubeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_953737_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      HesapAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_265099_value", "value", "", "", "")
        ),
        "string"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_534000_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      Swift_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariibankabilgileri_942080_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariIBankaBilgileri/CariIBankaBilgileriComponent_608676_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariBankaBilgileriSave = result?.data.setKartCariBankaBilgileriSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariIBankaBilgileriComponent_608676_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariIBankaBilgileriComponent_608676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariIBankaBilgileriComponent_727598_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
